// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require('jquery-ui');

import "../stylesheets/application"

require('filterrific')
require('rewardcard')



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

// = require jquery
// = require jquery_ujs
// = require jquery-ui/effect.all
// = require jquery-ui/datepicker
// = require rails-ujs
// = require activestorage
//   DISABLED require turbolinks
// = require bootstrap-sprockets
// = require bootstrap
// = require select2
// = require_tree .
