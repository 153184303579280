import $ from 'jquery'
import 'select2'

function init_select2() {

    var theme = "bootstrap-5";

    $(".rc-select-yn").select2({
        theme: theme
    });

    $(".rc-select-member-voucher-run-type").select2({
        theme: theme
    });

    $(".rc-select-valid-transaction").select2({
        theme: theme
    });

    $(".rc-select-voucher-status").select2({
        theme: theme
    });

    $(".rc-select-voucher-voucher-run-type").select2({
        theme: theme
    });

    $(".rc-select-voucher-rounded").select2({
        theme: theme
    });

    $(".rc-select-contact-type-code").select2({
        theme: theme
    });

    $(".rc-select-member-garden-card").select2({
        theme: theme
    });

    $(".rc-select-member-type").select2({
        theme: theme
    });

    $(".rc-select-voucherdetail-special-voucher-run").select2({
        theme: theme
    });

    $(".rc-select-voucherdetail-estimate-voucher-run").select2({
        theme: theme
    });

    $(".rc-select-member").select2({
        theme: theme,
        ajax: {
            url: "/members/select.json",
            dataType: "json",
            delay: 250,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                }
            },
            processResults: function (data) {
                return {
                    results: data
                };
            },
            cache: true
        },
        minimumInputLength: 3
    });

    $(".rc-select-customer-fixed").select2({
        theme: theme
    });

    $(".rc-select-customer").select2({
        theme: theme,
        ajax: {
            url: "/customers/select.json",
            dataType: "json",
            delay: 250,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                }
            },
            processResults: function (data) {
                return {
                    results: data
                };
            },
            cache: true
        },
        minimumInputLength: 3
    });

    $(".rc-select-initiative").select2({
        theme: theme
    });

}

function init_datepicker() {
    // $('.datepicker').datepicker({
    //     dateFormat: 'dd/mm/yy',
    //     changeMonth: true,
    //     changeYear: true
    // });
}

var cardnumber_input_timer;

function validate_ccm(card_number, customer_id, member_id) {
    $.ajax({
        url: "/cards/validatenewcard?card_number=" + card_number + "&customer_id=" + customer_id + "&member_id=" + member_id,
        success: function (result) {
            // alert("success "+result);
            $("#new-card-validation-notice").html(result);
        },
        error: function (result, status, error) {
            // alert("error "+result);
            $("#new-card-validation-notice").html("ERROR! " + status + ", " + error);
        }
    });

    // resultDiv = document.getElementById('customer-card-validation-notice')
    // message = "Validating card '" + card_number + "' and customer " + customer_id + " and member " + member_id
    // resultDiv.innerHTML = message
}

function on_field_input() {
    clearTimeout(cardnumber_input_timer);
    cardnumber_input_timer = setTimeout(function () {
        var card_number = document.getElementById('card_card_number')?.value || ''
        var customer_id = document.getElementById('card_customer_id')?.value || ''
        var member_id = document.getElementById('card_member_id')?.value || ''
        validate_ccm(card_number, customer_id, member_id)
    }, 500);
}

function init_ccm() {
    $('#card_card_number').on('input', function (e) { on_field_input() })
    $('#card_card_number').on('change', function (e) { on_field_input() })
    $('#card_customer_id').on('change', function (e) { on_field_input() })
    $('#card_member_id').on('change', function (e) { on_field_input() })

    // If this page has a new-card-validation-notice element, then validate the
    // the initial content immediately.
    if (document.getElementById('new-card-validation-notice')) {
        on_field_input();
    }
}

function rcInit() {
    init_select2();
    init_datepicker();
    init_ccm();
}

window.addEventListener('DOMContentLoaded', () => {
    rcInit()
})
