(function () {
  function Loader(joblogId, jobLinesFetchLimit, element) {

    this.element = element;
    this.joblogId = joblogId;
    this.lastJoblineId = '0';
    this.jobLinesFetchLimit = jobLinesFetchLimit;

    this.requestJobLogLater = function (numLines) {
      if (this.element == undefined) {
        console.log('No element for joblog ' + this.joblogId + ', so not requesting lines later');
        return;
      }

      setTimeout(function () {
        this.requestJobLog();
      }.bind(this), numLines > 0 ? 10 : 2000);
    };

    this.requestJobLog = function () {
      if (this.element == undefined) {
        Se.log('No element for joblog ' + this.joblogId + ', so not requesting lines now');
        return;
      }

      url =
        "/joblogs/" + this.joblogId +
        "/joblines.json" +
        "?after_id=" + this.lastJoblineId +
        "&limit=" + this.jobLinesFetchLimit;

      $.ajax({
        url: url,
        context: this,
        success: function (result) {
          if (this.element) {
            if (this.lastJoblineId == '0') {
              this.element.textContent = "";
            }

            lines = result['lines'];

            if (lines) {
              lines.forEach(function (line, index) {
                appendJobline(this.element, line);
                this.lastJoblineId = line['id'];
              }.bind(this));

              this.requestJobLogLater(lines.length);
            }
          }
        }.bind(this),
        error: function (result, status, error) {
          this.element.textContent = 'ERROR! ' + status + ", " + error;
          this.lastJoblineId = '0';
          this.requestJobLogLater(0);
        }.bind(this)
      });

    };

  }

  function appendJobline(element, jobline) {
    line = jobline['created_at'] + ' - ' + jobline['line'] + "\n";
    element.textContent += line;
  }

  function initJoblogLoaders(loaders) {
    dataName = 'joblog-lines';
    $('[data-' + dataName + ']').each(function (index, element) {
      joblogId = $(this).data(dataName);
      if (joblogId) {
        loader = new Loader(joblogId, 100, element);
        loader.requestJobLog();
        loaders.push(loader);
      }
    });
  }

  init = function () {
    initJoblogLoaders(gLoaders);
  }

  gLoaders = [];
  $(init);

})();
