(function () {
  lastDashboardId = '';

  function newLastDashboardId(newId) {
    if (lastDashboardId != newId) {
      // console.log('different ID, reload table');
      lastDashboardId = newId;
      // this  seems like the only way to execute a data-remote UJS call
      $("#dashboard-reload-btn").click();
    }
    else {
      // console.log('no change, try again later');
    }

    requestLastDashboardIdLater();
  }

  function requestLastDashboardId() {
    var url = "/dashboard_member_transactions/last.json";

    $.ajax({
      url: url,
      success: function (result) {
        thisLastDashboardId = result['id'];
        newLastDashboardId(thisLastDashboardId);
      },
      error: function (result, status, error) {
        console.log('error reported, maybe table is empty?');
        newLastDashboardId('none');
      }
    });
  }

  function requestLastDashboardIdLater() {
    setTimeout(function () {
      requestLastDashboardId();
    }, 5000);
  }

  function init() {
    lastDashboardId = $("#last-dashboard-id").val();
    if (lastDashboardId) {
      requestLastDashboardIdLater();
    }
  }

  $(init);
})();
